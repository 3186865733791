import { VStack, Img, Text } from '@chakra-ui/react';
import { Article } from './Article';

const SmallArticle = ({
  imageUrl,
  title,
  body
}: Pick<Article, 'imageUrl' | 'title' | 'body'>) => {
  return (
    <VStack minWidth="18rem" spacing="1.5rem" alignItems={'flex-start'}>
      <Img height="13.5rem" borderRadius="2rem" src={imageUrl} loading="lazy" />
      <Text textStyle="h3" as="h3">
        {title}
      </Text>
      <Text fontSize="0.8rem" color="whiteAlpha.700">
        {body}
      </Text>
    </VStack>
  );
};

export default SmallArticle;

import { toRem } from '@/helpers/toRem';
import { CupIcon } from '@/theme/Icons';
import { HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import Article from './Article';
import Card from './Card';
// import contentData from './ContentData';
import GamingHallBanner from './GamingHallsBanner';
import { getFallbackLanguage } from '@/helpers/lang';
type LanguageType = 'fr' | 'nl' | 'en';

type SmallArticleType = {
  title: string;
  body: string;
  imageUrl: string;
};

type ArticleType = {
  topTitle: string;
  buttonTitle: string;
  redirectionUrl: string;
  imageLeft: boolean;
  imageUrl: string;
  title: string;
  body: string;
  smallArticles: SmallArticleType[];
};

type CardsType = {
  imageUrl: string;
  title: string;
  body: string;
};
type AdvantagesType = {
  title?: string;
  cards?: CardsType[];
};

type GamingHallType = {
  imageUrl?: string | undefined;
  title?: string | undefined;
  body?: string | undefined;
  buttonTitle?: string | undefined;
  redirectionUrl?: string | undefined;
};
export type ContentData = {
  [key in LanguageType]: {
    title: string;
    subtitle: string;
    articles?: ArticleType[];
    advantages?: AdvantagesType;
    gamingHall?: GamingHallType;
  };
};

const Content = ({
  contentData,
  ...props
}: StackProps & { contentData: ContentData }) => {
  const { lang } = useTranslation();

  // console.log('content: ', contentData);
  return (
    <>
      <VStack
        as="main"
        spacing={{ base: '4rem', md: '6rem' }}
        marginX="auto"
        maxWidth="58rem"
        {...props}
      >
        <VStack
          as="section"
          maxWidth="41rem"
          marginX="2rem"
          align="center"
          alignItems="flex-start"
          textAlign="left"
          spacing="2rem"
        >
          <Text
            textStyle="h2"
            as="h2"
            lineHeight={{ base: '2.125rem', md: '2.625rem' }}
            fontSize={{ base: '1.5rem', md: '3rem' }}
            fontWeight="500"
            textAlign="left"
          >
            {
              contentData[getFallbackLanguage(lang) as keyof typeof contentData]
                .title
            }
          </Text>
          <Text
            fontSize={{ base: '1.125rem', md: '1.5rem' }}
            color="whiteAlpha.800"
            fontWeight="400"
            lineHeight={{ base: '1.625rem', md: '2.25rem' }}
          >
            {
              contentData[getFallbackLanguage(lang) as keyof typeof contentData]
                .subtitle
            }
          </Text>
        </VStack>
        {contentData[
          getFallbackLanguage(lang) as keyof typeof contentData
        ]?.articles?.map((article) => (
          <Article
            key={article.title}
            article={article}
            marginX="2rem !important"
            paddingX={['1rem', '1rem', '1rem', '0']}
            locale={lang}
          />
        ))}

        {Object.keys(
          contentData[getFallbackLanguage(lang) as keyof typeof contentData]
            .advantages || {}
        ).length ? (
          <VStack spacing="1.5rem" width="100%">
            <CupIcon width="1.9rem" height="1.9rem" />
            <Text
              textTransform="capitalize"
              fontSize="1.1rem"
              fontWeight="semibold"
              marginTop={toRem(5) + '!important'}
              textAlign="center"
            >
              {
                contentData[
                  getFallbackLanguage(lang) as keyof typeof contentData
                ]?.advantages?.title
              }
            </Text>
            <HStack
              display={['flex', 'flex', 'flex', 'grid']}
              gridTemplateColumns="repeat(3, 1fr)"
              width="100%"
              overflowX="scroll"
              overflowY="hidden"
              gridGap="1rem"
              alignSelf={['flex-start', 'flex-start', 'flex-start', 'center']}
              paddingX={['1rem', '1rem', '1rem', '0']}
              className="no-scrollbar"
            >
              {contentData[
                getFallbackLanguage(lang) as keyof typeof contentData
              ]?.advantages?.cards?.map((card, index) => (
                <Card
                  key={index}
                  imageUrl={card.imageUrl}
                  title={card.title}
                  body={card.body}
                />
              ))}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
      {Object.keys(
        contentData[getFallbackLanguage(lang) as keyof typeof contentData]
          .gamingHall || {}
      ).length ? (
        <GamingHallBanner
          marginTop="6rem"
          title={
            contentData[getFallbackLanguage(lang) as keyof typeof contentData]
              ?.gamingHall?.title
          }
          body={
            contentData[getFallbackLanguage(lang) as keyof typeof contentData]
              ?.gamingHall?.body
          }
          buttonTitle={
            contentData[getFallbackLanguage(lang) as keyof typeof contentData]
              ?.gamingHall?.buttonTitle
          }
          redirectionUrl={
            contentData[getFallbackLanguage(lang) as keyof typeof contentData]
              ?.gamingHall?.redirectionUrl
          }
          imageUrl={
            contentData[getFallbackLanguage(lang) as keyof typeof contentData]
              ?.gamingHall?.imageUrl
          }
        />
      ) : null}
    </>
  );
};

export default Content;

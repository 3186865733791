import {
  Box,
  Button,
  Img,
  Stack,
  StackProps,
  Text,
  VStack
} from '@chakra-ui/react';
import Link from 'next/link';

type Banner = StackProps & {
  buttonTitle?: string;
  redirectionUrl?: string;
  imageUrl?: string;
  title?: string;
  body?: string;
};

const GamingHallBanner = ({
  imageUrl,
  title,
  body,
  buttonTitle,
  redirectionUrl,
  ...props
}: Banner) => {
  return (
    <Stack
      width="100%"
      marginX="auto"
      height={['auto', 'auto', 'auto', '30rem']}
      maxW="120rem"
      flexDirection={['column', 'column', 'row', 'row']}
      {...props}
    >
      <Box display="flex" flexGrow="1">
        <Img
          src={imageUrl}
          objectFit="cover"
          width="100%"
          loading="lazy"
          alt="Gaming hall"
        />
      </Box>
      <VStack
        padding="2rem"
        margin="0 !important"
        alignItems={['center', 'center', 'center', 'flex-start']}
        backgroundColor="#21201F"
        justifyContent="center"
        spacing={[0, 0, 0, '1rem']}
        width={['100%', '100%', '100%', '30rem']}
        display="flex"
        flexGrow="1"
      >
        <Text fontWeight="semibold" fontSize="2rem">
          {title}
        </Text>
        <Text fontSize="1.1rem">{body}</Text>
        {!!redirectionUrl ? (
          <Link href={redirectionUrl} passHref>
            <Button
              as={'a'}
              backgroundColor="buttonPrimary"
              size="lg"
              marginTop={['1rem !important', '2rem !important']}
            >
              {buttonTitle}
            </Button>
          </Link>
        ) : null}
      </VStack>
    </Stack>
  );
};

export default GamingHallBanner;

import { toRem } from '@/helpers/toRem';
import { StackProps, VStack, Text, Img } from '@chakra-ui/react';
import { ReactElement } from 'react';

type CardProps = StackProps & {
  imageUrl: string;
  title: string;
  body: string;
};

const Card = ({ imageUrl, title, body, ...props }: CardProps) => {
  return (
    <VStack
      minWidth="18rem"
      height="20.5rem"
      backgroundColor="rgba(20, 20, 20, 0.3)"
      // justifyContent="space-between"
      spacing="1rem"
      padding="1.5rem"
      borderRadius="1.25rem"
      {...props}
    >
      <Img src={imageUrl} height={toRem(25)} loading="lazy" alt={title} />
      <Text
        textStyle="h3"
        as="h3"
        fontWeight={500}
        fontSize={toRem(18)}
        textAlign="center"
        marginTop={toRem(10) + '!important'}
      >
        {title}
      </Text>
      <Text
        textAlign={'center'}
        fontSize="0.8rem"
        color="whiteAlpha.800"
        marginTop={toRem(10) + '!important'}
      >
        {body}
      </Text>
    </VStack>
  );
};

export default Card;
